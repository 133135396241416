/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "headline_colors_default": "text-black after:bg-yves-klein-900",
  "headline_variant_default": "flex flex-row items-center after:relative after:-top-0.5 after:flex-grow",
  "headline_size_default": "text-gabor-sm sm:text-gabor-md gap-x-4 after:h-0.5",
  "slide_caption_variant_default": "",
  "slide_caption_colors_default": "text-gray-800",
  "slide_caption_size_default": "text-tag-md mt-3 mb-1",
  "slide_description_colors_default": "text-black",
  "slide_description_variant_default": "",
  "slide_description_size_default": "text-headline-2xs sm:text-headline-sm",
  "slide_group_variant_default": "[counter-reset:most-read] flex-nowrap flex-row flex overflow-x-auto overflow-y-hidden snap-x",
  "slide_group_colors_default": "scrollbar-thumb-primary-600",
  "slide_group_size_default": "gap-3",
  "slide_image_variant_default": "after:absolute after:content-[counter(most-read)] after:flex after:items-center after:justify-center relative",
  "slide_image_colors_primary": "after:bg-white after:text-black after:shadow-black/25",
  "slide_image_size_default": "min-w-75 after:h-9 after:right-1.5 after:translate-y-1/2 after:rounded-full text-headline-sm after:w-9 after:bottom-1 after:shadow-lg after:shadow-[0px_0px_4mm] [&_figure]:mb-0",
  "slide_variant_default": "[counter-increment:most-read] flex flex-col flex-1",
  "slide_colors_default": "",
  "slide_size_default": "",
  "colors_default": "text-black",
  "variant_default": "flex-col flex overflow-hidden",
  "size_default": "w-full gap-3 pt-6"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "MostRead");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;