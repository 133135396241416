import { useStableProps } from '@/hooks/useStable';
import StandaloneRelatedArticleSlider from 'base/components/RelatedArticleSlider';

export const ElleStandaloneRelatedArticleSlider: typeof StandaloneRelatedArticleSlider = (props) => {
  const stableProps = useStableProps(
    {
      slider: {
        options: {
          $slider: {
            $headline: {
              className: '!text-gabor-md uppercase',
            },
            className: '!bg-transparent',
            $caption: {
              className: 'my-2 !text-preview-sm',
            },
            $description: {
              className: '!text-headline-xs',
            },
          },
        },
      },
      options: {
        $arrow: {
          name: 'sliderArrow',
          className: 'rotate-180',
        },
      },
    },
    props,
  );

  return <StandaloneRelatedArticleSlider {...stableProps} />;
};

export default ElleStandaloneRelatedArticleSlider;
